.c-input-number {
  &.c-input-number__short {
    width: 62px;
  }

  &.c-input-number__3 {
    width: 50px;
  }
}

c-input-number {
  display: inline-block;
  width: 62px;

  &.c-input-number__3 {
    width: 50px;
  }

  .form-control {
    .form-inline & {
      width: 100%;
    }
  }
}
