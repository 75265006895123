c-booking-strategy-days-and-time {

  .-distribution {
    &.-distribution__selected {
      .-distribution_label {
        > button {
          font-weight: bold;
        }
      }
    }

    c-input-number {
      margin-left: 10px;
    }

    .-distribution_label {
      margin-bottom: 10px;

      > button {
        cursor: pointer;
        text-transform: uppercase;
      }
    }
  }

}

c-booking-strategy-days-and-time-distribution {
  ._read-mode-table {
    th {
      cursor: initial;
    }

    tr {

      padding: 0 !important;
      font-size: 0.95em;

      td {
        cursor: initial;
      }
    }

  }

  table-layout: fixed;

  th {
    width: 12.5%;
    cursor: pointer;

    &:first-child {
      cursor: initial;
    }
  }

  tr {
    &._next-day {
      border-top: 2px solid $color-red;
    }
  }

  td {
    cursor: pointer;

    &._selected-allow {
      background: $color-active;
    }

    &._selected-forbidden {
      background: $red;
    }
  }

  td {
    cursor: pointer;

    &._selection-owned-by-other {
      opacity: 0.5;
    }

    &._selection-mode-allow {
      background: $color-active;
    }

    &._selection-mode-forbidden {
      background: $red;
    }
  }
}
