.c-buttons {
  display: flex;
  align-items: center;

  &._gap-l {
    > * + * {
      margin-left: 10px;
    }
  }

  > * + * {
    margin-left: 5px;
  }
}
