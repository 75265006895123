c-booking-strategy-human-spots {
  label {
    font-weight: normal;
    margin-right: 10px;

    input[type="radio"] {
      margin-right: 4px;
    }
  }

  c-inventory-table-body {
    .c-inventory-table-item_affinity {
      font-size: 11px;
      font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      line-height: 1.42857143;
    }
  }

  c-program-break-booked._selected, c-inventory-table-item._selected {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 0;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
      font-size: 14px;
      content: "\f023";
    }
  }
}
