c-booking-strategy-black-white {
  .c-booking-strategy-black-white--spinner {
    padding: 50px 0;
    text-align: center;
  }

  .c-booking-strategy-black-white--program-break-ids {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .c-btn-blue-link-with-underline {
      position: relative;
      top: -1px;

      line-height: 20px;
      margin-left: 10px;
    }
  }

  c-inventory-table {
    table tbody > tr > td._holiday {
      background: rgba(253, 216, 213, 0.4);
    }
  }


  c-inventory-table-item {
    border: none;
    color: transparent;

    &._filtered {
      opacity: 0.1;
    }
  }
}

c-booking-strategy-black-white-program-select {
  .checkbox {
    label {
      padding-left: 10px;
    }
  }

  c-select-multiple-react {
    border: 1px solid #e5e6e7;
    display: block;
  }
}
