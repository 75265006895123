c-media-plans-filter {
  .ui-select-multiple {
    &.ui-select-container {
      padding: 4px 3px 3px;
    }

    .ui-select-match-item {
      background: transparent !important;
    }

    .ui-select-match {
      .close {
        padding-top: 2px;
      }
    }
  }
}