.c-ul {
  padding: 0;
  margin: 0;

  &.c-ul--inline-coma {
    li {
      display: inline-block;

      &:after {
        display: inline-block;
        content: ",";
        margin-right: 4px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}

.c-ul-dl {
  li {
    @include clearfix;
  }

  .c-ul-dl--dt {
    float: left;
    width: 200px;
  }

  .c-ul-dt--dd {
    margin-left: 200px;
    min-height: 32px;
  }
}