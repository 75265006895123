.c-task-item-table {
  display: block;
  overflow: auto;
  margin-left: -20px;
  margin-right: -20px;

  table {
    td {
      font-size: 12px;
    }

    th {
      font-weight: 600;
    }

    tr th:first-child, tr td:first-child {
      padding-left: 10px;
    }

    tr td:last-child {
      padding-right: 10px;
    }

    td.column_period {
      white-space: nowrap;
    }
  }
}