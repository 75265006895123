.c-muted {
  transition: opacity 0.3s ease-in-out;

  opacity: 0.5;
  margin-left: 3px;

  &:hover {
    opacity: 1;
  }
}

.c-muted-i {
  transition: opacity 0.3s ease-in-out;

  opacity: 0.5 !important;

  &:hover {
    opacity: 1 !important;
  }
}

.c-muted-light {
  transition: opacity 0.3s ease-in-out;

  opacity: 0.7;
  margin-left: 3px;

  &:hover {
    opacity: 1;
  }
}
