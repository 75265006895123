c-input-inline-change {

  &._state--read {
    .-read {
      display: inline-block;
    }
  }

  &._state--edit {
    .-edit {
      display: inline-block;
      width: 100%;
    }
  }

  &._empty {
    .-read {
      min-width: 30px;
      width: 100%;
    }
  }

  &._has-error {
    .-error {
      display: block;
    }
  }

  &._invalid {
    .-edit {
      input {
        border-color: #d04437 !important;
      }
    }
  }

  .-read {
    display: none;

    cursor: pointer;
    box-shadow: 0 0 0 0 transparent;
    transition: box-shadow 150ms ease-in 0ms;
    border: 1px solid transparent;
    border-radius: 2.01px;
    margin-left: -5px;
    padding: 2px 5px 2px 5px;
    z-index: 1;

    &:hover {
      box-shadow: 0 0 0 1px #ccc;
    }
  }

  .-edit {
    display: none;
    position: relative;

    input {
      margin-left: -5px;
      padding: 2px 5px 2px 5px;
      height: initial;
    }
  }

  .-error {
    display: none;

    position: absolute;
    top: 100%;
    left: -5px;
    right: 5px;
    background-color: #d04437;
    color: white;
    padding: 2px;
  }
}