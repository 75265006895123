c-advertisers-as-labels {
  @include labels-container;

  &._light-version {
    margin: 0;
    display: inline-block;

    .label {
      font-size: 100%;
      text-align: left;

      &._btn {
        border: none;
        padding: 0;
        margin: 0;
      }

      &:after {
        display: inline-block;
        content: ",";
        padding-right: 5px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    ._advertiser {
      color: #676a6c;
      font-weight: normal;
    }
  }

  > .label {
    white-space: initial;
  }
}