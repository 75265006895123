c-task-item-inventory-source-tab--remainders {
  th {
    &.-table-spots {
      width: 180px;
    }
  }

  tr {
    transition: background 0.3s ease-in-out;

    &._highlighted {
      background: rgba(250, 167, 50, 0.39);
    }
  }
}
