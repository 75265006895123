.-th--checkbox, .-td--checkbox {
  text-align: center;
  width: 30px;
}

._icon-margin-right {
  margin-right: 8px;
}

._link-action {
  @include link-action;
}

.fl-align-center {
  display: flex;
  align-items: center;
}

.fl-align-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
