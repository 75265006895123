c-no-ui-slider {
  &.noUi-target {
    display: block;
    background: #dddddd;
    border: none;
    box-shadow: none;
    height: 6px;
  }

  &.noUi-horizontal {
    .noUi-handle {
      height: 17px;
      background: #1ab394;
      box-shadow: none;
      border: none;

      &:before, &:after {
        height: 7px;
        top: 5px;
      }
    }
  }
}