c-booking-strategy-max-spots-per-day {
  ._read-only-mode {
    position: relative;

    > div {
      z-index: 1;
    }

    .-read-only-stub {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }

  .c-month-days-table {
    max-width: 900px;
    margin-left: 15px;
  }
}
