.navbar[role=navigation] {
  z-index: 2002;
}

#wrapper {
  display: flex;

  .navbar-default {
    position: relative;
    height: 100%;
  }

  .navbar-right {
    margin-right: 0;
  }

  #page-wrapper {
    margin-left: 0;
    flex-grow: 1;
    overflow: auto;
  }
}

.loginscreen {
  .m-login--logo {
    max-width: 300px;
  }
}

.table-striped > tbody > tr:nth-child(2n) > td, .table-striped > tbody > tr:nth-child(2n) > th {
  background-color: #f5f5f5;
}

.table-hover {
  tbody {
    tr {
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

.progress {
  background-color: #dddddd;
}

dl {
  margin-bottom: 10px;
}