c-flights-create-multiple-table {
  .c-flights-create-multiple-table--th-target-audience {
    width: 270px;
  }

  .c-flights-create-multiple-table--flight-meta {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 20px;
    }

    .c-flights-create-multiple-table--flight-meta--reach {
      display: flex;
      align-items: center;

      input {
        margin: 0 5px 0 0;
      }

      span {
        cursor: pointer;
      }
    }

    .c-flights-create-multiple-table--flight-meta--frequency {
      display: flex;
      align-items: center;

      input {
        width: 80px;
      }
    }
  }

  table {
    thead {
      th {
        vertical-align: middle !important;
      }

      .c-flights-create-multiple-table--thead-tr-sub-level {
        th {
          font-weight: normal;
          padding: 4px 8px;
          border-bottom: 0;
        }
      }
    }

    tbody {
      td {
        font-size: 12px;
        vertical-align: middle !important;

        input {
          font-size: 12px !important;
        }

        .fa {
          font-size: 14px;
        }

        &.c-flights-create-multiple-table--td-fix-line-height {
          line-height: 32px;
        }
      }
    }
  }
}
