.c-form-radio-vertical {
  label {
    font-weight: normal;
    margin-right: 10px;
    cursor: pointer;
    display: block;

    input {
      margin-right: 5px;
    }
  }
}
