c-booking-strategy-rating-distribution {
  text-align: center;
  color: #777;

  div.progress {
    background-color: $color-offprime;

    .progress-bar {
      background-color: $color-prime;
    }
  }

  .f-column {
    flex-direction: column;
  }

  .day-width {
    width: 14%;
  }

  .checkbox-size-md {
    height: 20px;
    width: 20px;
  }

  .fl-align-content-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fl-align-center {
    display: flex;
    align-items: center;
  }

  .width-input-percent {
    width: 50px;
  }


  ._m-l-a {
    margin-left: auto;
  }

  ._just-content-left {
    justify-items: left
  }

  @media (max-width: 1200px) {
    .scalable-text {
      font-size: small;
    }
  }

}

c-booking-strategy-rating-distribution-week-days-template-modal-body {
  .-days {
    padding: 0;

    li {
      display: inline-flex;
      flex: 1;
      align-items: center;
      padding-right: 10px;
      margin-bottom: 10px;
      margin-left: -5px;
      width: 124px;

      span {
        margin: 0 5px;
      }
    }
  }


}
