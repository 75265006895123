c-nav-contact {
  margin-left: 10px;
  margin-right: 20px;


  color: #999c9e;
  button.btn-link {
    font-weight: 600;

  }
}

.client-top-navbar {
  c-nav-contact {
    color: #a7b1c2;

    button.btn-link:hover, :focus {
      color: #a7b1c2;
    }
  }

}