c-task-status-icon {
  i {
    &._active {
      color: $blue;
    }

    &._paused {
      color: $blue;
    }

    &._back {
      color: $navy;
    }

    &._back_paused {
      color: $navy;
    }

    &._exception {
      color: $red;
    }
  }
}