c-months-select {
  .-input {
    position: relative;

    .-input-stub {
      position: relative;
      z-index: 1;

      min-width: 200px;
      max-width: 600px;

      padding: 6px 13px;
      visibility: hidden;
      height: 34px;
    }

    input {
      position: absolute !important;
      z-index: 2 !important;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
