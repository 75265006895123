.c-filter-actions {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 30px;
  display: inline;
  padding-left: 5px;

  li {
    @include link-action;

    display: inline;
    margin-right: 10px;
    font-size: 12px;

    &.c-filter-actions-item_unstyled {
      cursor: initial;
      color: initial;
      margin-right: 0;
      border-bottom: none;

      &:hover {
        color: initial;
      }
    }
  }
}

.c-filter-actions-item {
  @include link-action;

  display: inline;
  margin-right: 10px;
  font-size: 12px;
  line-height: 30px;

  &.c-filter-actions-item__active {
    font-weight: bold;
  }
}
