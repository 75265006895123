@media (max-width: 1279.98px) {
  .dynamic-font-size {
    font-size: 10px !important;
  }
}

@media (min-width: 1280px) and (max-width: 1365.98px) {
  .dynamic-font-size {
    font-size: 11px !important;
  }
}

@media (min-width: 1366px ) {
  .dynamic-font-size {
    font-size: 12px !important;
  }
}
