c-booking-strategy-predict-stats-body {
  .-loading {
    text-align: center;
    padding: 30px;
  }

  table {
    .-row {
      &._expandable {
        cursor: pointer;

        &._expanded {
          .-row_label {
            &:after {
              content: "\f0d8";
            }
          }
        }

        &._level-1 {
          .-row_label {
            &:after {
              bottom: 0;
            }
          }
        }

        .-row_label {
          position: relative;

          &:after {
            position: absolute;
            right: -12px;
            bottom: 2px;

            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            content: "\f0d7";
          }
        }
      }

      &._level-1 {
        font-size: 0.95em;

        .-row_label {
          padding-left: 10px;
        }
      }

      &._level-2 {
        font-size: 0.9em;

        .-row_label {
          padding-left: 20px;
        }
      }
    }
  }
}