.clockpicker-popover {
  z-index: 9999;
}

.app-planner {
  .modal-xl {
    width: 90%;
  }
}

.dl-horizontal {
  dt._white-space--normal {
    white-space: normal;
  }
}

.control-label-padding-top {
  padding-top: 7px;
}

.selectize-control .selectize-input.disabled {
  opacity: 0.8;
}

.selectize-control.multi .selectize-input.disabled > div, .selectize-control.multi .selectize-input.disabled > div.active {
  color: initial;
  opacity: 0.8;
}
