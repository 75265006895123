.c-btn-blue-link-with-underline {
  @include link-action;

  padding: 0;
  margin: 0;
  outline: none;
  background: transparent;
  border: none;
  border-bottom: 1px #337ab7 dashed;
}
