c-program-break-booked {
  @include c-program-break-as-item;

  position: relative;
  overflow: hidden;

  .-film-duration {
    position: relative;
    z-index: 2;
  }

  .c--mark {
    position: absolute;
    z-index: 1;
    width: 10px;
    top: 0;
    left: 100%;
    height: 10px;
    transform: rotate(45deg);
    margin-left: -5px;
    margin-top: -5px;
  }

  &._prime {
    background: $color-book-success;

    .c--mark {
      background: $color-prime;
    }
  }

  &._offprime {
    background: $color-book-success;

    .c--mark {
      background: $color-offprime;
    }
  }

  &._selected {
    background: $color-book-selected;
  }

  &._group {
    box-shadow: inset 0px 0px 0px 1px black;
  }

  &._forbidden-to-delete {
    background: $color-blue-light;
  }
}
