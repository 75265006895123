c-selectable-matrix-table-stub {

  ._read-mode-table {
    tr {

      padding: 0 !important;
      font-size: 0.95em;

      td {
        cursor: initial;
      }
    }

  }

  table-layout: fixed;

  th {
    width: 12.5%;
    cursor: pointer;

    &:first-child {
      cursor: initial;
    }
  }

  tr {
    &._next-day {
      border-top: 2px solid $color-red;
    }
  }

  td {
    cursor: pointer;

    &._selection-owned-by-other {
      opacity: 0.5;
    }

    &._selection-mode-allow {
      background: $color-active;
    }

    &._selection-mode-forbidden {
      background: $red;
    }
  }
}
