.c-th-filter-modal {
  z-index: 9999 !important;

  ul {
    padding: 0;
    max-height: 320px;
    overflow: auto;
  }

  li {
    cursor: pointer;
    padding: 8px 5px;

    &:hover {
      background-color: #f5f5f5;
    }

    > input[type=checkbox] {
      margin-right: 10px;
    }
  }
}