.c-affinity-sync-status-circle-icon {
  .c-affinity-sync-status-circle-icon--status {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: $color-alert;

    &._upToDate {
      background: $color-green;
    }
  }
}
