.c-media-plans-table {
  .c-media-plans-table_column-month {
    display: none;
  }

  &._hasColumnMonth {
    .c-media-plans-table_column-month {
      display: table-cell;
    }
  }
}
