c-flight-customize-modal-body {
  .c-flight-customize-modal-body--period {
    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .c-flight-customize-modal-body--weight {
    margin-top: 20px;

    c-no-ui-slider {
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }
}