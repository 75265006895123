c-media-plan-items-data-upload-btn {
  @include upload-button;
}

.c-media-plan-items-data-upload-btn-failure {
  min-width: 700px;
  text-align: left;

  ul {
    @include ul-unstiled;

    li {
      margin-bottom: 10px;
    }
  }

  .swal-text {
    padding: 0 30px;
  }
}