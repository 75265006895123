c-program-breaks-legend {
  transition: opacity 0.3s ease-in 0s;
  opacity: 0.5;
  text-transform: uppercase;

  &:hover {
    opacity: 1;
  }

  .-item {
    color: white;
    margin-left: 5px;
    display: inline-block;
    width: 110px;

    &._prime {
      background: $color-prime;
    }

    &._offprime {
      background: $color-offprime;
    }

    &._book-failure {
      background: $color-book-failure;
    }

    &._book-success {
      background: $color-book-success;
    }

    &._selected {
      background: $color-book-selected;
    }

    &._spots-source {
      background: $color-book-success;
    }

    &._spots-to-replace {
      background: $color-book-selected;
    }

    &._spots-replaced {
      background: #80017f;
    }

    &._spots-failed {
      background: $color-book-failure;
    }

    &._not-available {
      background: #d1dade;
    }
  }
}
