$tooltipster-border-color: #ccc;

.tooltipster-base {
  &.ad {
    .tooltipster-box {
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid $tooltipster-border-color;
      border-radius: 6px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    }

    .tooltipster-content {
      color: #676a6c;
    }

    &.tooltipster-right {
      .tooltipster-arrow-border {
        border-right-color: $tooltipster-border-color;
      }

      .tooltipster-arrow-background {
        border-right-color: white;
        left: 1px;
      }
    }

    &.tooltipster-left {
      .tooltipster-arrow-border {
        border-left-color: $tooltipster-border-color;
      }

      .tooltipster-arrow-background {
        border-left-color: white;
        left: -1px;
      }
    }

    &.tooltipster-top {
      .tooltipster-arrow-border {
        border-top-color: $tooltipster-border-color;
      }

      .tooltipster-arrow-background {
        border-top-color: white;
      }
    }

    &.tooltipster-bottom {
      .tooltipster-arrow-border {
        border-bottom-color: $tooltipster-border-color;
      }

      .tooltipster-arrow-background {
        border-bottom-color: white;
      }
    }
  }
}