.c-month-days-table {
  .c-month-days-table--week {
    margin-bottom: 20px;
    display: flex;
  }

  .c-month-days-table--day {
    width: 14%;
  }
}
