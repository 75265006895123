.c-inventory-table-item-tooltip {
  font-size: 12px;

  dl {
    margin-bottom: 0;
    min-width: 400px;
  }

  dt {
    float: left;
    width: 130px;
    line-height: 2em;
  }

  dd {
    margin-left: 160px;
    line-height: 2em;
    min-height: 2em;
  }

  .-spots {
    width: 100%;

    th {
      padding: 3px;
    }

    td {
      max-width: 210px;
      padding: 3px;
    }
  }

  .c-inventory-table-item-tooltip--actions {
    margin: 5px 0;
  }

  .c-inventory-table-item-tooltip--hourly-restrictions-input {
    display: inline-block;
    width: initial;
    height: 30px;
    vertical-align: middle;
  }
}