c-task-item-inventory-by-program {
  $font-size-normal: 12px;

  display: block;
  overflow-x: scroll;

  @mixin row-collected-info {
    font-size: $font-size-normal;

    > td {
      vertical-align: middle;
    }
  }

  table {
    table-layout: fixed;

    th {
      &._holiday {
        background: $color-holiday;
      }

      &.c--interval {
        width: 52px;
      }

      &.c--program {
        width: 250px;
      }

      &.-film-duration {
        width: 75px;
      }

      &.c--day {
        width: 30px;
      }
    }

    tr {
      &._next-day {
        border-top: 2px solid $color-red;
      }

      &.-row-film-duration-total {
        @include row-collected-info;

        background: #f5f5f6;
        font-weight: bold;
      }

      &.-row-film-duration-prime {
        @include row-collected-info;

        background: $color-prime;
        color: white;
      }

      &.-row-film-duration-offprime {
        @include row-collected-info;

        background: $color-offprime;
        color: white;
      }
    }

    td {
      &.c--day {
        padding: 2px 1px !important;
      }

      &._holiday {
        background: $color-holiday;
      }

      &.-td-total-film-duration {
        text-align: right;
        font-size: $font-size-normal;
      }

      &.c--td-total-day, &.c--td-prime-day, &.c--td-offprime-day {
        padding: 1px;
        text-align: center;
      }
    }
  }
}