.c-inline-blue-links {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 30px;
  display: inline;
  padding-left: 5px;

  li {
    @include link-action;

    display: inline;
    margin-right: 10px;

    &._active {
      color: #676a6c;
      cursor: initial;
      border-bottom: none;
    }
  }
}