.c-disablable-block {
  position: relative;

  > div {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: " ";
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1009;
    background: rgba(255, 255, 255, 0.7);
  }

  &._disabled {
    &._max-height-150 {
      max-height: 150px;
      overflow: hidden;
    }

    &::after {
      display: block;
    }
  }
}
