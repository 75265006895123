c-media-plans-filter-month {
  display: block;
  max-width: 250px;

  input {
    background: transparent !important;
  }

  > div.input-group {
    z-index: 2001;
  }
}