c-booking-strategy-spot-position {
  text-align: center;
  color: #777;

  div.progress {
    background-color: $color-offprime;

    .progress-bar {
      background-color: $color-prime;
    }
  }

  .width-input-percent {
    width: 45px;
  }

  ._m-l-none {
    margin-left: 0;
  }

  .fl-align-content-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._align-items-baseline{
    align-items: baseline;
  }

  ._just-content-between {
    justify-content: space-between;
  }

  ._just-content-left {
    justify-items: left
  }

  @media (max-width: 1200px) {
    .scalable-text {
      font-size: small;
    }
  }


}
