c-flight-media-plan-items {
  display: block;

  tr {
    cursor: pointer;
  }

  .-rating-plan {
    c-input-inline-change {
      width: 90px;
      display: inline-block;

      .-error {
        font-size: 12px;
      }
    }
  }
}