@mixin c-program-break-as-item {
  cursor: pointer;

  font-size: 11px;

  display: block;
  color: white;
  border: 1px solid black;
  border-radius: 2px;
  margin-bottom: 2px;
  text-align: center;

  &._prime {
    background: #00b494;
  }

  &._offprime {
    background: #1c84c6;
  }

  &._filtered {
    background: #d1dade;
  }

  &._filtered_by_reason {
    opacity: 0.7;
  }

  &._filtered_by_deadline, &._filtered_by_available_seconds {
    opacity: 0.3;
  }

  &._hidden {
    display: none;
  }
}
