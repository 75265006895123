$hours-width: 5%;

@function _dayWidth($days) {
  @return (100% - $hours-width)/$days;
}

c-inventory-table {
  table {
    table-layout: fixed;

    .c--thead-hours {
      width: $hours-width;
    }

    &._days-28 {
      .c--thead-day {
        width: _dayWidth(28);
      }
    }

    &._days-29 {
      .c--thead-day {
        width: _dayWidth(29);
      }
    }

    &._days-30 {
      .c--thead-day {
        width: _dayWidth(30);
      }
    }

    &._days-31 {
      .c--thead-day {
        width: _dayWidth(31);
      }
    }

    .c--thead-day {
      text-align: center;

      &._holiday {
        background: $color-holiday;
      }
    }

    thead {
      .c--thead-day {
        &._holiday {
          background: $color-holiday;
        }
      }
    }

    tbody {
      > tr {

        &._next-day {
          border-top: 2px solid $color-red;
        }

        > td {
          padding: 2px 1px !important;

          &._holiday {
            background: $color-holiday;
          }

          &:first-child {
            text-align: center;
          }
        }
      }
    }
  }
}