c-inventory-table-item {
  transition: box-shadow 0.5s ease-in-out 0.3s, opacity 0.5s ease-in-out;

  position: relative;
  overflow: hidden;

  @include c-program-break-as-item;

  c-inventory-table.-program-view-mode & {
    height: 6px;
  }

  ._is-hide-filtered-blocks & {
    &._filtered_by_reason {
      display: none;

      &._book-success, &._selected, &._book-failure {
        display: block;
      }
    }
  }

  &:after {
    content: " ";
    display: none;
    position: absolute;
    z-index: 1;
    width: 10px;
    top: 0;
    left: 100%;
    height: 10px;
    transform: rotate(45deg);
    margin-left: -5px;
    margin-top: -6px;
  }

  &._prime {
    &:after {
      background: #00b494;
    }
  }

  &._offprime {
    &:after {
      background: #1c84c6;
    }
  }

  &._book-failure {
    background: $color-book-failure;
  }

  &._book-success {
    background: $color-book-success;

    &:after {
      display: block;
    }
  }

  &._selected {
    background: $color-book-selected;
    opacity: 1;
  }

  &._selected-position-empty {
    background: #006600;
  }

  &._selected-position-1 {
    background: #90ED7D;
  }

  &._selected-position-2 {
    background: #630077;
  }

  &._selected-position--2 {
    background: #00695F;
  }

  &._selected-position--1 {
    background: #610100;
  }

  &._group {
    box-shadow: inset 0px 0px 0px 1px black;
  }

  &._multiple-bookings {
    box-shadow: inset 0px 0px 0px 1px red;
  }

  &._same-issuer-id {
    box-shadow: inset 0px 0px 0px 1px purple;
  }

  .c-inventory-table-item_grp-fluctuation, .c-inventory-table-item_affinity, .c-inventory-table-item_trp, .c-inventory-table-item_rank-data {
    &._rank--3 {
      background: #ef8470;
      color: #0e0e0e;
    }

    &._rank--2 {
      background: #f2b579;
      color: #0e0e0e;
    }

    &._rank--1 {
      background: #f8eb84;
      color: #0e0e0e;
    }

    &._rank-1 {
      background: #d1de83;
      color: #0e0e0e;
    }

    &._rank-2 {
      background: #9dcf7f;
      color: #0e0e0e;
    }

    &._rank-3 {
      background: #63be7b;
      color: #0e0e0e;
    }

    &._rank-0 {
      background: #d1dadee0;
    }
  }

  > span {
    display: block;
  }
}
