body.app-planner {
  .selectize-control {

    &.single {
      .selectize-input {
        > div {
          border: none;
        }
      }
    }

    &.plugin-remove_button {
      .remove, .remove-single {
        background: transparent;
        font-size: 21px;
        font-weight: bold;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.2;
        right: 3px;
        top: 0;
      }
    }

    .selectize-input {
      border: 1px solid #e5e6e7;
      border-radius: 1px;

      &.focus {
        border-color: #e5e6e7;
        outline: 0;
        box-shadow: none;
      }

      > div {
        background: transparent;
        border: 1px solid #e7eaec;
        border-radius: 3px;
        padding-left: 5px;

        &.active {
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
          border-color: #d2d2d2;
        }
      }
    }

    .selectize-dropdown {
      border-radius: 1px;
    }

  }
}
