@mixin c-task-item-rating-prime--legend {
  transition: left .6s ease;

  position: absolute;
  border-left: 2px solid #676a6c;
  height: 50px;
}

@mixin c-task-item-rating-prime--legend-item {
  position: absolute;
  font-size: 12px;
  min-width: 140px;
}

.dl-horizontal {
  &._c-task-item-rating-prime {
    dt {
      margin-top: 30px;
    }
  }
}

c-task-item-rating-prime {
  display: block;

  > div {
    margin-top: 30px;
    margin-bottom: 30px;

    &._has-prime-ratio {
      .-prime-expected-legend {
        display: block;
      }
    }
  }

  .-progress-wrapper {
    position: relative;

    .progress {
      border-radius: 0;
    }
  }

  .-overflow-icon {
    position: absolute;
    left: 100%;
    top: 0;
    line-height: 20px;
    color: $color-alert;
    margin-left: 15px;
  }

  .progress-bar {
    background-color: transparent;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.68);

    &.-prime {
      background-color: $color-prime;
      overflow: hidden;
    }

    &.-offprime {
      background-color: $color-offprime;
      overflow: hidden;
    }

    > span {
      position: relative;
      z-index: 1;
    }
  }

  .-plan-legend {
    @include c-task-item-rating-prime--legend;

    bottom: 0;

    .-plan-legend--right {
      @include c-task-item-rating-prime--legend-item;

      top: 0;
      left: 0;
      text-align: left;
      padding-left: 10px;
    }

    .money {
      margin-top: -4px;
      font-size: 10px;
      font-weight: bold;
      line-height: 1.42857143;
    }
  }

  .-prime-expected-legend {
    @include c-task-item-rating-prime--legend;

    display: none;
    bottom: 0;

    .-prime-expected-legend--left {
      @include c-task-item-rating-prime--legend-item;

      top: 0;
      right: 0;
      text-align: right;
      padding-right: 10px;
    }
  }

  .-fact-legend {
    @include c-task-item-rating-prime--legend;

    top: 0;

    .-fact-legend--left {
      @include c-task-item-rating-prime--legend-item;

      top: 25px;
      right: 0;
      text-align: right;
      padding-right: 10px;
    }

    .-fact-legend--right {
      @include c-task-item-rating-prime--legend-item;

      top: 25px;
      left: 0;
      text-align: left;
      padding-left: 10px;
    }

    .money {
      margin-top: -4px;
      font-size: 10px;
      font-weight: bold;
      line-height: 1.42857143;
    }
  }
}