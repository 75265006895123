@mixin c-flight-rating-prime--legend {
  transition: left .6s ease;

  position: absolute;
  border-left: 2px solid #676a6c;
  height: 50px;
}

@mixin c-flight-rating-prime--legend-item {
  position: absolute;
  font-size: 12px;
  min-width: 140px;
}

.dl-horizontal {
  &._c-flight-rating-prime {
    dt {
      margin-top: 30px;
    }

    .-percentage-of-total {
      position: absolute;
      top: 0;
      left: 100%;
      width: 80px;
      font-size: 12px;
      margin-left: 10px;
    }
  }
}

c-flight-rating-prime {
  display: block;

  > div {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .-progress-wrapper {
    position: relative;

    .progress {
      border-radius: 0;
    }
  }

  .progress-bar {
    background-color: transparent;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.68);

    &.-prime {
      background-color: $color-prime;
      overflow: hidden;
    }

    &.-offprime {
      background-color: $color-offprime;
      overflow: hidden;
    }

    > span {
      position: relative;
      z-index: 1;
    }
  }

  .-budget-legend {
    @include c-flight-rating-prime--legend;

    bottom: 0;

    .-budget-legend--right {
      @include c-flight-rating-prime--legend-item;

      top: 0;
      left: 0;
      text-align: left;
      padding-left: 10px;
    }

    .-budget-legend--left {
      @include c-flight-rating-prime--legend-item;

      top: 0;
      right: 0;
      text-align: right;
      padding-right: 10px;
    }
  }
}