c-simple-checkbox {
  cursor: pointer;

  > span {
    display: flex;
    align-items: center;

    > input[type="radio"], input[type="checkbox"] {
      margin: 0;
    }

    span {
      margin-left: 5px;
    }
  }
}
