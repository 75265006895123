/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.6.2
 *
*/
// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
// Variables, Mixins
@import "helpers/all";
// INSPINIA Theme Elements
@import "base/typography";
@import "components/navigation";
@import "layout/top_navigation";
@import "components/buttons";
@import "components/badgets_labels";
@import "elements";
@import "layout/sidebar";
@import "layout/fontSize";
@import "layout/layout";
@import "base/base";
@import "pages/pages";
@import "components/chat";
@import "components/metismenu";
@import "components/spinners";
// Landing page styles
@import "landing";
// INSPINIA Skins
@import "skins";
@import "md-skin";
// Media query style
@import "media";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }
  body {
    overflow: visible !important;
  }

  #page-wrapper {
    margin: 0;
  }
}

@import "base/base.custom";
@import "base/base.other";
@import "base/bootstrap";
@import "base/bootstrap.fix";
@import "components/c-admin-graph-book-duration-by-blocks-count";
@import "components/c-advertiser-excel-data-upload-btn";
@import "components/c-advertisers-as-labels";
@import "components/c-affinity-sync-status-circle-icon";
@import "components/c-agencies-as-labels";
@import "components/c-book-grp-fluctuation-decrease-mode-table";
@import "components/c-booking-strategy";
@import "components/c-booking-strategy-days-and-time";
@import "components/c-booking-strategy-days-and-time-forbid-switcher";
@import "components/c-booking-strategy-formula-weights-modal-body";
@import "components/c-booking-strategy-human-spots";
@import "components/c-booking-strategy-joint-booking-set-modal-body";
@import "components/c-booking-strategy-min-spots-per-day";
@import "components/c-booking-strategy-max-spots-per-day";
@import "components/c-booking-strategy-predict-stats-body";
@import "components/c-booking-strategy-black-white";
@import "components/c-booking-strategy-black-white-list";
@import "components/c-booking-strategy-black-white-template-select";
@import "components/c-booking-strategy-rating-distribution";
@import "components/c-booking-strategy-spot-position";
@import "components/c-booking-strategy-template-select";
@import "components/c-btn-blue-link";
@import "components/c-btn-blue-link-with-underline";
@import "components/c-buttons";
@import "components/c-change-with-icon";
@import "components/c-channel-group-select";
@import "components/c-channel-minute-icon";
@import "components/c-checkbox-label.scss";
@import "components/c-comma-separated-list";
@import "components/c-dev-ops-release-version";
@import "components/c-disablable-block";
@import "components/c-film-replace-task-item-inventory-table";
@import "components/c-filter-action-with-checkbox";
@import "components/c-filter-actions";
@import "components/c-flight-customize-modal-body";
@import "components/c-flight-media-plan-items";
@import "components/c-flight-media-plan-items-add-modal-body";
@import "components/c-flight-select";
@import "components/c-flight-split-modal-body";
@import "components/c-flights-create-multiple-table";
@import "components/c-flight-rating-prime";
@import "components/c-form-radio-horizontal";
@import "components/c-form-radio-verical";
@import "components/c-help-icon";
@import "components/c-inline-blue-links";
@import "components/c-input-inline-change";
@import "components/c-input-number";
@import "components/c-input-select2";
@import "components/c-link-underline";
@import "components/c-media-plan-items-data-upload-btn";
@import "components/c-media-plans-filter";
@import "components/c-media-plans-filter-month";
@import "components/c-media-plans-table";
@import "components/c-month-days-table";
@import "components/c-month-picker-link";
@import "components/c-month-select";
@import "components/c-months-select";
@import "components/c-muted";
@import "components/c-nav-contact";
@import "components/c-nav-profile";
@import "components/c-no-ui-slider";
@import "components/c-prime-progress-bar";
@import "components/c-program-break-booked";
@import "components/c-program-breaks-legend";
@import "components/c-inventory-table";
@import "components/c-inventory-table-item";
@import "components/c-inventory-table-item-tooltip";
@import "components/c-media-plan-edit-params-modal-body";
@import "components/c-replace-unused-time-graph";
@import "components/c-select-multiple-react";
@import "components/c-selectable-matrix-table-stub";
@import "components/c-simple-checkbox";
@import "components/c-simple-checkbox-multiple";
@import "components/c-sync-prediction-data";
@import "components/c-table";
@import "components/c-table-selected";
@import "components/c-target-audience-select-modal-body";
@import "components/c-task-item-background-booking--table";
@import "components/c-task-item-book-failure-extended-stats";
@import "components/c-task-item-booking-strategy--position-change";
@import "components/c-task-item-booking-strategy-position-graph";
@import "components/c-task-item-booking-strategy-spots-distribution";
@import "components/c-task-item-booking-strategy-info-icon";
@import "components/c-task-item-booking-strategy-tab";
@import "components/c-task-item-booking-strategy-tab-explicit-booking";
@import "components/c-task-item-booking-strategy-upload-excel";
@import "components/c-task-item-booking-strategy-warning-icon";
@import "components/c-task-item-inventory-by-program";
@import "components/c-task-item-inventory-extended-stats";
@import "components/c-task-item-inventory-source-tab--remainders";
@import "components/c-task-item-inventory-source-tab--table";
@import "components/c-task-item-planning-report-tab";
@import "components/c-task-item-rating-prime";
@import "components/c-task-item-status";
@import "components/c-task-status";
@import "components/c-task-status-icon";
@import "components/c-task-upload";
@import "components/c-task-item-target-goal";
@import "components/c-task-item-table";
@import "components/c-task-items-bs-joint-booking-group-highlighter";
@import "components/c-task-items-group-highlighter";
@import "components/c-task-list-completion-rate";
@import "components/c-task--uploaded-with-errors-info";
@import "components/c-th-filter";
@import "components/c-ul";
@import "components/c-upload-button";
@import "components/c-year-select";
@import "components/selectize";
@import "components/smart-table";
@import "components/nav";
@import "components/other";
@import "components/tooltipster";
@import "components/tooltip";
@import "components/uib-tabset";
@import "md-skin.fix";
