.label {
  &._btn {
    background: transparent;
    border: 1px solid #d2d2d2;
    border-radius: 3px;
  }
}

._text-align--center {
  text-align: center;
}

._text-align--left {
  text-align: left;
}

._text-align--left-i {
  text-align: left !important;
}

.tooltip {
  &.in {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .modal-450 {
    width: 450px;
  }
}

@media (min-width: 992px) {
  .modal-xl {
    width: 90%;
  }
}

table {
  &.table {
    .table--loading {
      padding: 30px;
    }

    .table--thead-multi {
      tr {
        &:nth-child(2n + 1) {
          th {
            border: none;
          }
        }

        &:nth-child(2n) {
          border-top: none;
          font-size: 0.9em;
        }
      }
    }
  }
}

.modal-header {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background: $color-blue-light;
  color: white;
}

.selectize-control {
  &.single {
    .selectize-input {
      &::after {
        display: none;
      }

      &.dropdown-active:after {
        display: none;
      }
    }
  }
}