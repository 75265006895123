.js-task-items-group-highlighter--item {
  transition: background 0.3s ease-in-out;
  background: transparent;

  &._group-highlight-hover {
    background: rgba(35, 198, 200, 0.39);
  }
}

.table-hover {
  .js-task-items-group-highlighter--item {
    &._group-highlight-hover {
      background: rgba(35, 198, 200, 0.39);
    }
  }
}
