[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

[disabled] {
  pointer-events: none;
}

.d-flex {
  display: flex;
}

._display--block {
  display: block !important;
}

._display--inline-block {
  display: inline-block !important;
}

._display--none {
  display: none !important;
}

._display--flex {
  display: flex !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.gap-xxs {
  gap: 5px;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._justify-content--space-between {
  justify-content: space-between;
}

._justify-content--end {
  justify-content: end;
}

._line-height--100 {
  line-height: 100%;
}

._line-height--button {
  line-height: 34px;
}

._white-space--nowrap {
  white-space: nowrap;
}

._table-layout--fixed {
  table-layout: fixed;
}

._padding-horizontal--0-i {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

._padding-left--0 {
  padding-left: 0;
}

._padding-top--0 {
  padding-top: 0;
}

._padding-bottom--0 {
  padding-bottom: 0;
}

._margin-top--50 {
  margin-top: 50px;
}

._border-top--0 {
  border-top: none !important;
}

._margin-horizontal--10 {
  margin-left: 10px;
  margin-right: 10px;
}

._margin-vertical--10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

._position--relative {
  position: relative;
}

._cursor--pointer {
  cursor: pointer;
}

._cursor-initial-i {
  cursor: initial !important;
}

._cursor--initial-deep-i {
  * {
    cursor: initial !important;
  }
}

._width--80 {
  width: 80px;
}

._width--100p {
  width: 100%;
}

._width--30p {
  width: 30%;
}

._font-size--120p {
  font-size: 120%;
}

._font-size--140p {
  font-size: 140%;
}

._font-size--90p {
  font-size: 90%;
}

._font-size--80p {
  font-size: 80%;
}

._font-size--note {
  font-size: 12px;
}

._font-weight--normal {
  font-weight: normal;
}

._font-weight--bold {
  font-weight: bold;
}

._white-space--pre-wrap {
  white-space: pre-wrap;
}

button {
  &.unstyled {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    outline: none;
  }
}

.link-blue {
  cursor: pointer;
  color: #337ab7;
  text-decoration: none;
}

._background--green-light-i {
background: #edf9f7 !important;
}

._color--alert {
  color: $color-alert;
}

._color--green {
  color: $color-green;
}

._color--red {
  color: $color-alert;
}

._visibility--hidden {
  visibility: hidden;
}

._text-align--right {
  text-align: right;
}

._vertical-align--middle-i {
  vertical-align: middle !important;
}

._max-width--150 {
  max-width: 150px;
}

._transition--opacity {
  transition: opacity 0.3s ease-in-out;
}

.fa-with-label {
  + span {
    padding-left: 5px;
  }
}

._btn-reset {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

._todo--b-w {
  //display: none !important;
}

.pretty-hidden {
  display: none !important;
}

button {
  i {
    margin-right: 8px;
  }
}


i.no-icon-margin {
  margin-right: 0;
}


a {
  i {
    margin-right: 8px;
  }
}

a.navbar-minimalize {
  i {
    margin-right: 0;
  }
}

a.task-status {
  i {
    margin-right: 0;
  }
}

.client-top-navbar {
  .navbar-right {
    a {
      color: #a7b1c2 !important;
    }
    a:hover {
      color: #a7b1c2 !important;
    }
  }
}

.aaa-list {
  white-space: initial;
  display: inline-block;
  margin: 2px;
  font-size: 14px;
}
