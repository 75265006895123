#wrapper {
  height: 100%;
}

#wrapper-nav {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  flex-grow: 0;
  flex-shrink: 0;
}
