c-filter-action-with-checkbox {
  input {
    vertical-align: middle;
    margin: 0 !important;
  }

  span {
    margin-left: 4px;
  }
}
