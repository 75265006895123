c-task-item-background-booking-table {
  .-minimize-width {
    width: 1%;
  }

  table {
    tbody {
      tr {
        &:last-child {
          background: #edf9f7;
        }

        &:first-child {
          background: $color-yellow-row-highlight;
        }
      }
    }
  }
}