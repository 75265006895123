c-month-select {
  display: inline-block;
  max-width: 200px;
  cursor: pointer;

  .c-month-select_disabled {
    cursor: initial;

    .input-group-addon {
      background-color: #eee;
    }
  }

  .form-control[readonly] {
    background: transparent;
  }

  .form-control[disabled] {
    background-color: #eee;
  }
}