c-agencies-as-labels {

  > span {
    &:after {
      display: inline-block;
      content: ",";
      padding-right: 5px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

}