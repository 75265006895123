@import "variables";
@import "c-program-break";

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin line-height-initial {
  line-height: 1.42857143;
}

@mixin labels-container {
  .label {
    display: inline-block;

    margin: 0 2px;
  }

  margin-left: -2px;
  margin-right: -2px;
}

@mixin link-action {
  cursor: pointer;
  color: #337ab7;
  margin-right: 10px;
  border-bottom: 1px #337ab7 dashed;

  &:hover {
    color: #23527c;
  }
}

@mixin upload-button {
  display: inline-block;

  > input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

@mixin ul-unstiled {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}
