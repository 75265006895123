c-flight-split-modal-body {
  .c-flight-split-modal-body--select-all {
    @include link-action;
  }

  table {
    thead {
      th {
        vertical-align: middle !important;
      }

      .c-flight-split-modal-body--thead-tr-sub-level {
        th {
          font-weight: normal;
          padding: 4px 8px;
          border-bottom: 0;
        }
      }
    }

    tbody {
      td {
        font-size: 12px;

        input {
          font-size: 12px;
        }

        c-media-plan-item-fixed-floating-icon {
          display: inline-block;
          margin-top: 4px;
        }

        .fa {
          font-size: 14px;
        }
      }
    }
  }
}