.c-book-grp-fluctuation-decrease-mode-table {
  ._completion-status_book-result-failure {
    .-td--ratio-fact {
      color: red;
      font-weight: 600;
    }
  }

  ._completion-status_book-result-success {
    .-td--ratio-fact {
      color: green;
      font-weight: 600;
    }
  }

  ._completion-status_simulation-result-failure {
    .-td--ratio-simulation {
      color: red;
      font-weight: 600;
    }
  }

  ._completion-status_simulation-result-success {
    .-td--ratio-simulation {
      color: green;
      font-weight: 600;
    }
  }
}
