c-booking-strategy-formula-weights-modal-body {

  .modal-body {
    padding: 20px 40px;
  }

  .c-formula-weights-modal {
    dl {
      margin: 10px;
    }

    dt {
      margin: 10px 0;
    }

    dd {
      margin-bottom: 30px;
    }
  }

}
