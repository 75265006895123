c-task-item-inventory-source-tab--table {
  tr {
    transition: background 0.3s ease-in-out;

    &._highlighted {
      background: rgba(250, 167, 50, 0.39);
    }
  }

  th {
    &.-table-checkbox {
      width: 30px;
    }

    &.-table-spots {
      width: 180px;
      text-align: right;
    }
  }

  td {
    &.-table-spots {
      line-height: 50px;
      text-align: right;

      .-table-spots--spots {
        display: inline-block;
        vertical-align: middle;
      }

      c-task-item-inventory-source-tab-booking-histogram {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
