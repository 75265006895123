.navbar-default {
  display: flex;
  flex-direction: column;
}

.nav-footer {
  flex: 1;

  display: flex;
  flex-direction: column;

  color: #2f4050;
  padding: 2px 10px;
  font-size: 9px;
  text-align: center;

  .nav-footer_spacer {
    flex: 1;
  }

  .nav-footer_sk {
    position: sticky;
    bottom: 0;
    height: 56px;
    margin: auto;
    opacity: 0.9;
    margin-top: 20px;
    padding-bottom: 20px;
  }
}

.mini-navbar {
  .nav-footer_sk {
    display: none;
  }
}
