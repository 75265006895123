c-booking-strategy {
  .c--time {
    button {
      margin-bottom: 10px;
    }
  }

  .at-multirange-slider {
    .slider {
      height: 6px;
      background: #dddddd;
    }

    .slider-range {
      height: 6px;
    }

    .slider-handle {
      background: #1ab394;
      border-color: transparent;

      width: 30px;
      height: 14px;
      margin-top: -7px + 3px;

      &:hover {
        background: #3cbea4;
      }
    }
  }

  .c--prime-ratio {
    input[type=text] {
      width: 50px;
    }

    c-no-ui-slider {
      .noUi-base {
        background: $color-offprime;
      }

      .noUi-connect {
        background: $color-prime;
      }
    }
  }

  .c--row {
    margin-top: 20px;

    dt {
      line-height: 30px;
    }

    dd {
      padding-top: 10px;
    }
  }

  .c--rating-distribution {
    input[type=text] {
      width: 50px
    }
  }

  c-group-applied-warning {
    i {
      margin-left: 5px;
      position: relative;
      top: 2px;
      color: $color-alert;
      font-size: 17px;
    }
  }

  .c-booking-strategy--rating-distribution-not-available {
    display: none;
    background: white;
    opacity: 0.7;
  }

  .c-booking-strategy--strategy-available-parent {
    position: relative;
  }

  .c-booking-strategy--strategy-available {
    display: none;
    background: white;
    opacity: 0.7;
  }

  .c-booking-strategy--strategy-not-available {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background: rgba(255, 255, 255, 0.7);
  }

  .c-booking-strategy--deadline-booking {
    input[type=text] {
      width: 50px;
    }
  }

  .c-booking-strategy--spots-min-interval {
    input[type=text] {
      width: 50px;
    }
  }

  .c-booking-strategy_affinity {
    &.c-booking-strategy_affinity__FIXED {
      .c-booking-strategy_affinity_addExclude {
        margin-left: 60px;
      }
    }

    &.c-booking-strategy_affinity__FLOATING_PERIOD {
      .c-booking-strategy_affinity_addExclude {
        margin-left: 175px;
      }
    }

    &.c-booking-strategy_affinity__FLOATING_PERIOD_TO {
      .c-booking-strategy_affinity_addExclude {
        margin-left: 100px;
      }
    }

    .c-booking-strategy_affinity_min_value {
      c-input-number {
        width: 90px;
      }
    }

    .c-booking-strategy_affinity_historical-period_floating {
      display: flex;
      align-items: center;

      input {
        max-width: 50px;
      }
    }

    .c-booking-strategy_affinity_target-value {
      display: flex;
      align-items: center;
      margin-top: 20px;

      c-simple-checkbox {
        margin-right: 15px;
      }
    }

    .c-booking-strategy_affinity_min-value {
      display: flex;
      align-items: center;
      margin-top: 20px;

      c-simple-checkbox {
        margin-right: 15px;
      }
    }
  }
}

c-booking-strategy-state-switcher {
  .btn {
    width: 58px;
  }
}
