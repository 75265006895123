.c-table {
  &.c-table--sticky-header {
    thead {
      position: sticky;
      top: 0;
      background: white;
    }
  }

  .c-table_selection {
    display: none;
    width: 40px;
  }

  c-th-filter {
    display: none;
  }

  &._hasSelection {
    .c-table_selection {
      display: table-cell;
    }

    tr {
      cursor: pointer;
    }
  }

  &._hasFilter {
    c-th-filter {
      display: inline;
    }
  }
}

.st-pagination-compact {
  .pagination {
    margin: 10px 0;
  }
}
