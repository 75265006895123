.c-smart-table {
  tbody {
    tr {
      transition: opacity, background 0.3s ease-in-out;

      opacity: 1;

      &._highlighted {
        background: $color-yellow-row-highlight;
      }

      &._hoverBackgroundTransparent {
        &:hover {
          background: transparent;
        }
      }
    }

    &._loading {
      tr {
        opacity: 0;
      }
    }
  }

  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &[st-sort] {
      cursor: pointer;
    }

    &:hover {
      .fa-filter {
        opacity: 1;
      }
    }

    .fa-sort {
      display: none;
      padding-right: 5px;
    }

    .fa-filter {
      transition: opacity 0.3s ease-in-out;

      opacity: 0;
      color: $color-blue;
      padding: 5px;
    }
  }

  td {
    &._loading {
      padding: 30px;
    }
  }

  .st-sort-ascent {
    .fa-sort {
      display: inline-block;
      position: relative;
      top: 3px;

      &:before {
        content: "\f0de";
      }
    }
  }

  .st-sort-descent {
    .fa-sort {
      display: inline-block;
      position: relative;
      top: -3px;

      &:before {
        content: "\f0dd";
      }
    }
  }
}

.c-smart-table {
  tbody {
    tr.js-task-items-group-highlighter--item, tr.js-task-items-bs-joint-booking-group-highlighter--item {
      transition: all 0.3s ease-in-out;
    }
  }
}
