c-task-item-booking-strategy-tab {
  c-booking-strategy {
    position: relative;
    z-index: 2;
    display: block;
  }

  .-months {
    display: inline-flex;
    gap: 20px;
    align-items: center;

    c-month-select-from-option {
      min-width: 180px;
    }
  }
}

.-booking-strategy {
  display: flex;

  c-booking-strategy {
    flex: 1;
  }

  .-booking-strategy_predict-stats {
    overflow: auto;
    height: 100vh;
    width: 500px;
    position: sticky;
    top: 0;
    align-self: flex-start;

    c-booking-strategy-predict-stats {
      height: 100%;
      display: block;
    }
  }

}


.-booking-strategy {
  display: flex;

  c-booking-strategy {
    flex: 1;
  }

  .-booking-strategy_predict-stats {
    overflow: auto;
    height: 100vh;
    width: 500px;
    position: sticky;
    top: 0;
    align-self: flex-start;

    c-booking-strategy-predict-stats {
      height: 100%;
      display: block;
    }
  }

}
