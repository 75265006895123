c-film-replace-task-item-inventory-table {
  c-inventory-table-item {

    &._spots-source {
      background: $color-book-success;
    }

    &._spots-to-replace {
      background: $color-book-selected;
    }

    &._spots-failed {
      background: $color-book-failure;
    }

    &._spots-replaced {
      background: #80017f;
    }

  }
}
